import React from "react"

import Logo from "../components/logo"
import Footer from "../components/footer"
import MainLayout from "../components/Layout/MainLayout"
//img
import behavorialEconomics from "../images/img/affiliate/behavorialeconomics.png"
import clickSuasion from "../images/img/affiliate/clicksuasion.png"
import userNudge from "../images/img/affiliate/usernudge.png"
import { sendEmail } from "../lib/sendEmail"

class AffiliatePage extends React.Component {
  state = {
    isOpenForm: false,
    name: "",
    email: "",
    linkedin: "",
    reason: "",
    message: ""
  }

  handleOpenForm = () => {
    this.setState({
      isOpenForm: true,
    })
  }

  handleChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = e => {
    e.preventDefault()

    const emailTXT = `
    - Name: ${this.state.name}\n
    - Email: ${this.state.email}\n
    - Linkedin: ${this.state.linkedin}\n
    - Reason: ${this.state.reason}
    `

    const emailHtML = `
    <strong>Name</strong>: ${this.state.name}<br>
    <strong>Email</strong>: ${this.state.email}<br>
    <strong>Linkedin</strong>: ${this.state.linkedin}<br>
    <strong>Reason</strong>: ${this.state.reason}
    `

    // Sends composed email
    sendEmail({
      site: 'affiliate-program',
      subject: 'Affiliate form',
      text: emailTXT,
      html: emailHtML
    })
      .then(_ => {
        this.setState({ message: 'Thank you! We’ll get back to you soon.' })
      })
      .catch(_ => {
        this.setState({ message: 'Error has occurred, please check all fields and try submitting again.' })
      })
  }

  render() {
    const { isOpenForm } = this.state
    const { handleOpenForm, handleSubmit } = this
    return (
      <MainLayout>
        <div className="affiliate-main-wrapper">
          <div className="logoWrapper" style={{ margin: "0 auto" }}>
            <a href="/#" title="MINDWORX" className="logo">
              <Logo style={{ width: "100%", height: "100%" }} />
            </a>
          </div>
          <h1 className="affiliate-title">Become our affiliate</h1>
          <div className="afilliate-сontent-wrapper">
            <p className="afilliate-сontent__text">
              Do you like the masterclass and know people who’d love it too?
              Become our affiliate partner and earn a commission for every
              student who enrolls thanks to you.
            </p>
            <p className="afilliate-сontent__text">
              Just let us know a little more about yourself and we’ll get back
              to you with more info and our affiliate guidelines.
            </p>
            <div className="afilliate-buttom-wrapper">
              {isOpenForm ? (
                <button
                  onClick={handleOpenForm}
                  type="submit"
                  disabled
                  className="affiliate__button--disabled"
                >
                  I want to know more
                </button>
              ) : (
                  <button
                    onClick={handleOpenForm}
                    type="submit"
                    className="affiliate__button"
                  >
                    I want to know more
                  </button>
                )}
            </div>
          </div>
          {isOpenForm && (
            <div className="affiliate-form-wrapper">
              <form method="POST">
                <div className="affiliate-form__combinationItems">
                  <div className="affiliate-form__item affiliate_combi">
                    <label className="affiliate-form__label" htmlFor="name">
                      Your name
                    </label>
                    <input
                      className="affiliate-form__input"
                      name="name"
                      required=""
                      type="text"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="affiliate-form__item affiliate_combi">
                    <label className="affiliate-form__label" htmlFor="email">
                      Your email address
                    </label>
                    <input
                      className="affiliate-form__input"
                      name="email"
                      required=""
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="affiliate-form__item">
                  <label className="affiliate-form__label" htmlFor="linkedin">
                    Your Linkedin so we can learn a bit more about you
                  </label>
                  <input
                    className="affiliate-form__input"
                    name="linkedin"
                    required=""
                    type="text"
                    value={this.state.linkedin}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="affiliate-form__item">
                  <label className="affiliate-form__label" htmlFor="reason">
                    Why would you like to become an affiliate?
                  </label>
                  <input
                    className="affiliate-form__input"
                    name="reason"
                    required=""
                    type="text"
                    value={this.state.reason}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="affiliate__button-wrapper">
                  <button
                    type="button"
                    className="affiliate__button--submit"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
              {/*   <div id="affiliate-form-success">
                <div className="affiliate-form__icon-wrapper">
                  <img
                    alt="payment succes"
                    src={paymentSuccessIcon}
                    title="succes"
                  />
                </div>
                <p className="affiliate-payment__text">
                  {" "}
                  Thank you! We’ll get back to you soon.
                </p>
              </div> */}

              {/* <div id="affiliate-form-failure"> */}
              {/* <div className="affiliate-form__icon-wrapper">
                  <img
                    alt="payment failure"
                    src={paymentFailureIcon}
                    title="failure"
                  />
                </div> */}
              <p className="affiliate-payment__text">
                {this.state.message !== '' ? this.state.message : null}
              </p>
              {/* </div> */}
            </div>
          )}

          <p className="affiliate-partners-title">
            Here are some of our partners:
          </p>
          <div className="affiliate-partners-wrapper">
            <a href="https://usernudge.com/" target="_blank">
              <img
                alt="usernudge"
                src={userNudge}
                title="userNudge"
                className="affiliate-partners-item"
              />
            </a>{" "}
            <a href="https://www.clicksuasion.com/" target="_blank">
              <img
                alt="clicksuasion"
                src={clickSuasion}
                title="clicksuasion"
                className="affiliate-partners-item"
              />
            </a>{" "}
            <a href="https://www.behavioraleconomics.com/" target="_blank">
              <img
                alt="behavorialeconomics"
                src={behavorialEconomics}
                title="behavorialEconomics"
                className="affiliate-partners-item"
              />
            </a>
          </div>
        </div>
        <Footer />
      </MainLayout>
    )
  }
}

export default AffiliatePage
